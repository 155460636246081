



























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ApiHelper } from '@/ApiHelper';
import { AppModule } from '@/store/app';

@Component <Page> ({
  components: {
  },
  head: {
    title() { 
      return { inner: this.documentTitle }
    },
    meta() {
      return [
        { property: 'keyword', content: 'なわてん,大阪電気通信大学,四條畷キャンパス,卒業研究,卒業制作展,総合情報学部,デジタルゲーム学科,情報学科,医療健康科学部', id: 'metakeyword'},
        { property: 'description', content: this.excerpt, id: 'metadescription'},
        { property: 'og:title', content: this.documentTitle, id:'ogtitle' },
        { property: 'og:type', content: 'website', id: 'ogtype' },
        { property: 'og:description', content: 'なわてん - 2024年度 大阪電気通信大学 四條畷キャンパス 卒業研究・卒業制作展 総合情報学部、デジタルゲーム学科、情報学科、医療健康科学部 2025年2月8日（土）、9日（日）', id: 'ogdescription'},
        { property: 'og:url', content: document.URL, id: 'ogurl' },
        { property: 'og:image', content: document.URL + 'img/ogp.png', id:'ogimage' },
        { property: 'twitter:card', content: 'summary_large_image', id:'twittercard' },
        { property: 'twitter:site', content: '@nawaten_info', id: 'twittersite' },
      ];
    },
  },
})
export default class Page extends Vue {
  private page = '';
  private documentTitle = '';
  private excerpt = '';

  private created() {
    AppModule.setLoaded(false);
  }
  private mounted() {
    this.getpage();
  }
  @Watch('$route.params.slug')
  private getpage(){

    // 記事
    ApiHelper.callApi(`/wp-json/wp/v2/pages/?slug=${this.$route.params.slug}&_embed`, 'get', {}, res => {
      this.page = res.data[0];
      this.documentTitle = res.data[0].title.rendered;
      this.excerpt = res.data[0].excerpt.rendered;
      this.$emit('updateHead');
      AppModule.setLoaded(true);
    });
  }
}
